import store from "../store/index";
function checkNullNan(value) {
  return value &&
    !!value &&
    value.toString() != "NA" &&
    value.toString() != "-" &&
    !isNaN(value)
    ? true
    : false;
}
export default {
  methods: {
    realisedProfitLoss(data, key) {
      let buyAvgKey = key == "mtm" ? "mtmBuyPrice" : "buyPrice";
      let sellAvgKey = key == "mtm" ? "mtmSellprice" : "sellPrice";
      let netBuyQty = "buyQty";
      let netSellQty = "sellQty";
      var realise = 0;
      var closedQty = "";
      var tempQty = 0;

      if (parseFloat(data[netBuyQty]) > 0 && parseFloat(data[netSellQty]) > 0) {
        if (parseFloat(data[netBuyQty]) > parseFloat(data[netSellQty])) {
          closedQty = parseFloat(data[netSellQty]);
        } else {
          closedQty = parseFloat(data[netBuyQty]);
        }

        closedQty = checkNullNan(closedQty) ? closedQty : 0;
        if (
          data.exchange == "NFO" ||
          data.exchange == "BFO" ||
          data.exchange == "NSE" ||
          data.exchange == "BSE"
        ) {
          tempQty = closedQty;
        } else if (data.exchange == "MCX" && data?.pnlLotsize) {
          tempQty = closedQty * parseFloat(data["pnlLotsize"]);
        } else {
          tempQty = closedQty * parseFloat(data["lotsize"]);
        }
        realise =
          tempQty *
          (parseFloat(data[sellAvgKey]) - parseFloat(data[buyAvgKey]));

        realise = data.exchange == "CDS" ? realise * 1000 : realise;
        return realise;
      } else {
        return realise;
      }
    },

    unRealisedProfitLoss(data, key) {
      let buyAvgKey = key == "mtm" ? "mtmBuyPrice" : "buyPrice";
      let sellAvgKey = key == "mtm" ? "mtmSellprice" : "sellPrice";
      var unRealise = 0;
      let netQty = Number(data["netQty"]);
      if (parseFloat(netQty) != 0) {
        var tempAvgPrc =
          parseFloat(netQty) > 0
            ? parseFloat(data["ltp"]) - parseFloat(data[buyAvgKey])
            : parseFloat(data[sellAvgKey]) - parseFloat(data["ltp"]);
        var tempQtyMul = 0;
        if (
          data.exchange == "NFO" ||
          data.exchange == "BFO" ||
          data.exchange == "NSE" ||
          data.exchange == "BSE"
        ) {
          tempQtyMul = parseFloat(netQty);
        } else if (data.exchange == "MCX" && data?.pnlLotsize) {
          tempQtyMul = parseFloat(netQty) * parseFloat(data["pnlLotsize"]);
        } else {
          tempQtyMul = parseFloat(netQty) * parseFloat(data["lotsize"]);
        }
        if (tempQtyMul < 0) {
          tempQtyMul = tempQtyMul * -1;
        }
        unRealise = tempQtyMul * tempAvgPrc;
        unRealise = data.exchange == "CDS" ? unRealise * 1000 : unRealise;
        return unRealise;
      } else {
        return unRealise;
      }
    },


    getLotSize(lot, qty, exch) {
      qty = !qty ? "0" : exch == "MCX" ? qty / lot : qty;
      return qty;
    },

    convertToNumber(value) {
      return value.toString().replaceAll(/,/g, "");
    },

    /**Change Undefined or Null to Empty*/
    nullUndefined(data) {
      if (
        data == null ||
        data == undefined ||
        data == "null" ||
        data == "N/A" ||
        data == "n/a" ||
        data == "NaN" ||
        data == "NA"
      ) {
        return (data = "");
      } else {
        return data;
      }
    },

    /**
     * Method to calculate netpnl
     * @returns
     */
    netPnlCalc(data) {
      let uploadedPrc = parseFloat(data["buyPrice"]);
      //
      let tradedQty = parseFloat(data["tradedQty"]);
      let sellAmount = data["sellAmount"] ? parseFloat(data["sellAmount"]) : 0;
      let ltp = parseFloat(data["ltp"]);
      let netQty = parseFloat(data["netQty"]);
      let netPnl = 0;

      let realizedPnl = sellAmount - uploadedPrc * tradedQty;
      let unrealizedPnl = (netQty - tradedQty) * (ltp - uploadedPrc);
      netPnl = realizedPnl + unrealizedPnl;
      data["netPnl"] = netPnl;
      return parseFloat(netPnl).toFixed(2);
    },

    /**
     * Method to calculate netpnlchg
     * @returns
     */
    netChgCalc(data) {
      // formula ==> ((ltp - buyPrice) / buyPrice)  * 100
      let netChg = 0;
      netChg =
        ((parseFloat(data["ltp"]) - parseFloat(data["buyPrice"])) /
          parseFloat(data["buyPrice"])) *
        100;
      netChg.toString().toLowerCase() == "infinity" ? (netChg = 0) : "";
      netChg = parseFloat(netChg).toFixed(2);
      data["netChg"] = netChg;
      return netChg;
    },

    /**
     * Method to calculate daypnlchg
     * @returns
     */
    dayChgCalc(data) {
      // formula ==> ((ltp - pdc) / pdc)  * 100
      let dayChg = 0;
      let pdc = data["pdc"];
      dayChg = ((parseFloat(data["ltp"]) - parseFloat(pdc)) / pdc) * 100;
      dayChg.toString().toLowerCase() == "infinity" ? (dayChg = 0) : "";
      dayChg = parseFloat(dayChg).toFixed(2);
      data["dayChg"] = dayChg;
      return dayChg;
    },

    /**
     * Method to calculate daypnl
     * @returns
     */
    netDaysPnlCalc(data) {
      let pdc = parseFloat(data["pdc"]);
      //
      let tradedQty = parseFloat(data["tradedQty"]);
      let sellAmount = data["sellAmount"] ? parseFloat(data["sellAmount"]) : 0;
      let ltp = parseFloat(data["ltp"]);
      let netQty = parseFloat(data["netQty"]);
      let daysPnl = 0;

      let realizedPnl = sellAmount - pdc * tradedQty;
      let unrealizedPnl = (netQty - tradedQty) * (ltp - pdc);
      daysPnl = realizedPnl + unrealizedPnl;
      data["daysPnl"] = daysPnl;
      return daysPnl;
    },

    /**
     * Method to calculate Total Investment Value
     * @returns
     */
    totalInvestment(group, key) {
      let total = [];
      if (group) {
        group.forEach((val) => {
          total.push(val[key]);
        });
        let invest = parseFloat(
          total.reduce(function (total, num) {
            return parseFloat(total) + parseFloat(num);
          }, 0)
        ).toFixed(2);
        invest = this.nullUndefined(invest) ? invest : 0;
        return invest;
      }
    },

    /**
     * Method to calculate Total Current Value
     * @returns
     */
    totalCurrentValue(array) {
      let sum = 0
      if (array && array?.length != 0) {
        
        array.forEach((el) => {
          sum += parseFloat(el.currentVal)
        })
      }
      return (parseFloat(sum).toFixed(2));
    },

    /**
     * Method to calculate Total Net PNL
     * @returns
     */
    totalNetPnl(group, key) {
      let total = [];
      if (group) {
        group.forEach((val) => {
          total.push(val[key]);
        });
        let pnl = parseFloat(
          total.reduce(function (total, num) {
            return parseFloat(total) + parseFloat(num);
          }, 0)
        ).toFixed(2);

        pnl = this.nullUndefined(pnl) ? pnl : 0;
        return pnl;
      }
    },

    /**
     * Method to calculate Total Net PNL Chg
     * @returns
     */
    totalNetPnlChg(array, key) {
      let totalchg = 0;
      if (array && array?.length != 0) {
        let totalVal = Number(this.totalNetPnl(array, "buyValue"));
        totalchg =
          totalVal == 0
            ? 0
            : ((Number(this.totalCurrentValue(array)) - totalVal) / totalVal) * 100;
        totalchg = totalchg == "Infinity" ? "NA" : totalchg;
        totalchg ? "" : (totalchg = 0);
      }
      totalchg = this.nullUndefined(totalchg) ? totalchg : 0;
      if (key == "style") {
        return parseFloat(totalchg).toFixed(2);
      } else {
        return ` (${parseFloat(totalchg).toFixed(2)}%)`;
      }
    },

    /**
     * Method to calculate Total Day's PNL Chg
     * @returns
     */
    totalDaysPnl(array,type) {
      let sum = 0;
      if (array && array.length != 0) {
        array.forEach((el) => {
          sum += this.netDaysPnlCalc(el);
        });
        sum = this.nullUndefined(sum) ? parseFloat(sum).toFixed(2) : 0.0;
      }
      return parseFloat(sum).toFixed(2);
    },

    /**
     * Method to calculate Total Day's PNL Chg
     * @returns
     */
    totalDaysPnlChg(array, key, show) {
      let totalchg = 0;
      if (array && array.length != 0) {
        let totalPdc = this.getTotalPdc(array);
        if(Number(totalPdc)> 0){
          let differ = (parseFloat(this.totalCurrentValue(array)) - totalPdc)
          totalchg = (differ/totalPdc) * 100
        }
      }
      return show == "style"
        ? parseFloat(totalchg).toFixed(2)
        : `  (${parseFloat(totalchg).toFixed(2)}%)`;
    },

    getChg(ltp, pdc, exchange){
      let chg  = Number(ltp) - Number(pdc)
      
      chg ? '' : chg = 0
      return parseFloat(chg).toFixed(exchange == 'CDS' || exchange == 'BCD' ? 4 : 2)
    },
    getTotalPdc(array) {
      let totalPdc = 0
      if(array && array.length > 0) {
        array.forEach((data) => {
              if(data['pdc'] && data['netQty']) {
                  totalPdc += (Number(data['pdc']) * Number(data['netQty']))
              }
          })
      }
      return totalPdc;
  },
  individualCurrentVal(item){
    let sellAmt = item['sellAmount'] &&  parseFloat(item['sellAmount']) > 0 ? parseFloat(item['sellAmount']) : 0
    let actualQty = Number(item.netQty) - Number(item.tradedQty)
    let activeAmt = Number(item.ltp) * actualQty;
    let sum = 0 
    sum = sellAmt + activeAmt
    sum = parseFloat(sum).toFixed(2)

    return sum
  }
  },
};
