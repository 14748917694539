let envType = "UAT"
export function env() {
  return {
   env: envType,
    SERVICE_BASEURL: "./",
    depolyedUrl: envType == "Live" ? "https://web.gwcindia.in/chartFrame/" : "https://dev-gigapro.gwcindia.in/chartFrame/",
    BASEURL: envType == "Live" ? "https://web.gwcindia.in" : 'https://dev-gigapro.gwcindia.in/',
    CHARTURL: "https://web.gwcindia.in/rest/ChartAPIService",
    WEBSOCKETURL: envType == "Live" ? "wss://giga.gwcindia.in/NorenWSCOD/" : "wss://giga.gwcindia.in/NorenWSCOD/", //wss://giga.gwcindia.in/NorenWSTP/ //wss://giga.gwcindia.in/NorenWSCOD/
    endpoint: {
      position: `po-rest/positions`,
      order: `od-rest/info/orderbook`,
      trade: `od-rest/info/tradebook`,
      holdings: `ho-rest/holdings`,
    },
    IPOAPPCODE: "PPxsTEIluREkAWW",
    REEKYCCODE: "ToGNAQiqtbJRadp",
    payinCode: "fWQYBnjCZfhYDpq",
    PLEDGEAPPCODE: "DeeybRjUcWCSPrR",
    localUrl: "http://localhost:8081/chartFrame/"
  };
}

export function getModule() {
  return {
     loginModule: envType == 'Live' ? 'auth' : 'auth/userauth'
  }
}
