import { env, getModule } from "../helpers/env";
import { getHeaders } from "../helpers/auth-header";
import axios from "axios";

const AXIOS = axios.create({
  baseURL: env().BASEURL,
});

const module = getModule().loginModule

export const loginService = {
  // WEB Login Methods
  verifyClient,
  validatePassword,
  sendWebAuthOtp,
  verifyWebAuthOtp,
  resetPassword,
  forgotPass,
  forgotPassVerify,
  unblockAcc,
  unblockAccVerify,
  // TOTP
  registerTotp,
  getScannerData,
  enableTotp,
  verifyTotp,
  changePassword,
  getEncryption
};

function isAccessNeed(isAccess){
 return isAccess && env().env == "Live" ? `${module}/access` : module
}
function verifyClient(payload) {
  return AXIOS.post(`${isAccessNeed(true)}/client/verify`, payload)
}

function validatePassword(payload) {
  return AXIOS.post(`${isAccessNeed(false)}/pwd/validate`, payload)
}

function sendWebAuthOtp(payload) {
  return AXIOS.post(`${isAccessNeed(false)}/otp/send`, payload, getHeaders(false, false, false))
}

function verifyWebAuthOtp(payload) {
  return AXIOS.post(`${isAccessNeed(false)}/otp/validate`, payload)
}

function resetPassword(payload) {
  const requestOptions = {
    headers: loginAuthHead(),
  };
  return AXIOS.post(
    `${isAccessNeed(true)}/pwd/reset`,
    payload,
    requestOptions
  )
}
function changePassword(payload) {

  
  return AXIOS.post(`${isAccessNeed(false)}/change/pwd`, payload)
}

function forgotPass(payload) {
  
  return AXIOS.post(`${isAccessNeed(false)}/forgot/pwd`, payload)
}

function forgotPassVerify(payload) {
  return AXIOS.post(`${isAccessNeed(true)}/pwd/forget/verify`, payload)
}

function unblockAcc(payload) {
  return AXIOS.post(`${isAccessNeed(false)}/unblock`, payload)
}

function unblockAccVerify(payload) {
  return AXIOS.post(`${isAccessNeed(true)}/client/unblock/verify`, payload)
}

// TOTP APIS 
function registerTotp(payload) {
  return AXIOS.post(
    `${isAccessNeed(false)}/scanner/generate`,
    payload,
    getHeaders(false, false, false)
  )
}

function getScannerData(payload) {
  return AXIOS.post(
    `${isAccessNeed(true)}/scanner/get`,
    payload,
    getHeaders(false, false, false)
  )
}

function enableTotp(payload) {
  return AXIOS.post(
    `${isAccessNeed(true)}/topt/enable`,
    payload,
    getHeaders(false, false, false)
  )
}

function verifyTotp(payload) {
  return AXIOS.post(
    `${isAccessNeed(true)}/topt/verify`,
    payload,
    getHeaders(false, false, false)
  )
}


function getEncryption(payload){
  return AXIOS.post(`${isAccessNeed(false)}/client/enckey`,payload)
}