import { commonService } from "../../services/common.service";
import formula from "@/mixins/formula";
import errorHandling from "../../handleError/errHandling";
const state = {
  totalInvestment: 0,
  totalcurrentValue: 0,
  totalPnlChange: 0,
  daysPnlChange: 0,
  totalPnl: 0,
  daysPnl: 0,
  isAuthorization: false,
  isEdisDialog: false,
  isHoldServiceCalled: false,
  authHoldings: "",
  edisResp: "",
  isLoading: false,
  authBtnLoading: false,
  cdslBtnLoading: false,
  holdingLoader: false,
  holdingsList: [],
  selectedCheckBox: [],
  pludgeUrl: "",
  edisDialogCondition: false,
  isRevoke: false,
  pludgeDpId: "",
  edisWithdrawLoader: false,
};

const actions = {
  async getEdis({ state, commit, rootGetters }, payload) {
    commit("setEdisWithdrawLoader", true);
    await commonService
      .getEdis()
      .then(
        (res) => {
          if (res.status == 200) {
            const url = "https://giga.gwcindia.in";
            let userId = rootGetters["login/getUCC"]
              ? rootGetters["login/getUCC"]
              : "";

            let hstoken = res.data.result[0].hstk;

            let brokerId = "GWC";
            //&open=edis
            let edisString = `sLoginId=${userId}&sAccountId=${userId}&prd=C&token=${hstoken}&sBrokerId=${brokerId}`;

            let encodedString = window.btoa(edisString);

            let endpoint =
              payload == "holdings"
                ? "/NorenEdis/NonPoaHoldings?"
                : "/NorenExtrLn/Payout?";
            let redirectUrl = `${url}${endpoint}${encodedString}`;

            redirectUrl ? window.open(redirectUrl) : "";
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setEdisWithdrawLoader", false);
      });
  },
  async getUserHoldings(
    { state, commit, dispatch, rootGetters, rootState },
    payload
  ) {
    commit("setLoader", true);
    await commonService
      .getUserHoldings()
      .then(
        async (response) => {
          if (
            response.status == 200 &&
            response.data.status == "Ok" &&
            response.data?.result[0]
          ) {
            let holdList = [];
            let authList =[]
            let tickerData = window.tickers;
            response.data?.result[0].poa ? commit("setIsAuthorization", response.data?.result[0].poa) : ''
            for (let item of response.data.result) {
              for (let hold of item.holdings) {
                let nseArray = hold.symbol.filter(
                  (x) => x.exchange && x.exchange == "NSE"
                );

                let bseArray = hold.symbol.filter(
                  (x) => x.exchange && x.exchange == "BSE"
                );

                hold.buyValue =
                  parseFloat(hold.buyPrice) * parseFloat(hold.netQty);
                hold.buyValue = parseFloat(hold.buyValue).toFixed(2);

                if (nseArray && nseArray.length > 0) {
                  hold.exchange = nseArray[0].exchange;
                  hold.ltp = Number(nseArray[0].ltp);
                  hold.pdc = Number(nseArray[0].pdc);
                  hold.token = nseArray[0].token;
                  hold.tradingSymbol = nseArray[0].tradingSymbol;
                }

                if (
                  bseArray &&
                  bseArray?.length > 0 &&
                  nseArray &&
                  nseArray?.length == 0
                ) {
                  hold.exchange = bseArray[0].exchange;
                  hold.ltp = Number(bseArray[0].ltp);
                  hold.token = bseArray[0].token;
                  hold.tradingSymbol = bseArray[0].tradingSymbol;
                  hold.pdc = Number(bseArray[0].pdc);
                }
                if(tickerData && tickerData[`${hold.exchange}|${hold.token}`]){

                if (tickerData[`${hold.exchange}|${hold.token}`].lp) {
                  hold.ltp = tickerData[`${hold.exchange}|${hold.token}`].lp;
                  hold.symbol[0].ltp =
                    tickerData[`${hold.exchange}|${hold.token}`].lp;
                }
                if (tickerData[`${hold.exchange}|${hold.token}`].c) {
                  hold.pdc = tickerData[`${hold.exchange}|${hold.token}`].c;
                }

                }
                //Formula for pnl
                hold.currentVal = formula.methods.individualCurrentVal(hold)
                hold.netPnl = formula.methods.netPnlCalc(hold);
                hold.netChg = formula.methods.netChgCalc(hold);
                hold.dayChg = formula.methods.dayChgCalc(hold);
                hold.daypnl = formula.methods.netDaysPnlCalc(hold);
                
                if (hold.hasOwnProperty("authFlag") && !hold.authFlag) {
                  authList.push(hold);
                }

                holdList.push(hold);
                
              }
              commit("setHoldingsList", holdList);

              rootState.holdingsTabList[0].value =
                rootState.holdingsTabList[0].name;
              rootState.holdingsTabList[0].value =
                holdList?.length > 0
                  ? rootState.holdingsTabList[0].value +
                    " " +
                    `(${holdList.length})`
                  : rootState.holdingsTabList[0].name;
            }
            dispatch('checkWebsocketStatus')
          } else {
            commit("setHoldingsList", []);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },
  checkWebsocketStatus({ state, dispatch, rootGetters }) {
    if (rootGetters["wsConnection/getConnectionStatue"]) {
      var tempData = {
        data: state.holdingsList || [],
        where: "holdings",
      };

      dispatch("wsConnection/websocketSubscription", tempData, { root: true });
    }
  },
  async getAuthorizeHoldings(
    { state, commit, dispatch, rootGetters },
    payload
  ) {
    commit("setIsLoading", true);
    await commonService
      .getAuthorizeHoldings()
      .then(
        (response) => {
          if (
            response.status == 200 &&
            response.data.status == "Ok" &&
            response.data.message != "no data"
          ) {
            commit("setAuthHoldings", response.data?.result[0].holdings);
          } else {
            commit("setAuthHoldings", []);
          }
        },
        (err) => {
          commit("setIsLoading", false);
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setIsLoading", false);
      });
  },
  async initAuthorize({ state, commit, dispatch, rootGetters }, payload) {
    commit("setAuthBtnLoading", true);
    await commonService
      .initAuthorize(payload)
      .then(
        (response) => {
          if (
            response.status == 200 &&
            response.data.status == "Ok" &&
            response.data?.result[0]
          ) {
            commit("setEdisResp", response.data?.result[0]);
          } else {
            commit("setEdisResp", "");
          }
        },
        (err) => {
          commit("setAuthBtnLoading", false);
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setAuthBtnLoading", false);
      });
  },
};

const mutations = {
  resetState(state) {
    state.totalInvestment = 0;
    state.totalcurrentValue = 0;
    state.totalPnlChange = 0;
    state.daysPnlChange = 0;
    state.totalPnl = 0;
    state.daysPnl = 0;
    state.isAuthorization = false;
    state.isEdisDialog = false;
    state.isHoldServiceCalled = false;
    state.authHoldings = "";
    state.edisResp = "";
    state.isLoading = false;
    state.authBtnLoading = false;
    state.cdslBtnLoading = false;
    state.holdingLoader = false;
    state.holdingsList = [];
    state.selectedCheckBox = [];
    state.pludgeUrl = "";
    state.edisDialogCondition = false;
    state.isRevoke = false;
    state.pludgeDpId = "";
  },
  setEdisCondition(state, payload) {
    state.edisDialogCondition = payload;
  },
  setEdisWithdrawLoader(state, payload) {
    state.edisWithdrawLoader = payload;
  },
  setSelectcheckData(state, payload) {
    state.selectedCheckBox = payload;
  },
  setHoldingsList(state, payload) {
    state.holdingsList = payload;
  },
  setLoader(state, payload) {
    state.holdingLoader = payload;
  },
  setIsHoldServiceCalled(state, payload) {
    state.isHoldServiceCalled = payload;
  },
  setIsAuthorization(state, payload) {
    state.isAuthorization = payload;
  },
  setIsEdisDialog(state, payload) {
    state.isEdisDialog = payload;
  },
  setAuthHoldings(state, payload) {
    payload.forEach((el) => {
      el.qtyvmodel = el.totalQty;
      el.haveError = false;
      el.isChecked = false;
    });
    state.authHoldings = payload;
  },
  setEdisResp(state, payload) {
    state.edisResp = payload;
  },
  setIsLoading(state, payload) {
    state.isLoading = payload;
  },
  setAuthBtnLoading(state, payload) {
    state.authBtnLoading = payload;
  },
  setCdslBtnLoading(state, payload) {
    state.cdslBtnLoading = payload;
  }
};

const getters = {
  getHoldingsData: (state) => state.holdingsList,
  //
  getTotalInvestment: (state) => state.totalInvestment,
  getTotalcurrentValue: (state) => state.totalcurrentValue,
  getTotalPnlChange: (state) => state.totalPnlChange,
  getDaysPnlChange: (state) => state.daysPnlChange,
  getDaysPnl: (state) => state.daysPnl,
  getTotalPnl: (state) => state.totalPnl,
  getIsAuthorization: (state) => state.isAuthorization,
  getIsEdisDialog: (state) => state.isEdisDialog,
  getAuthHoldings: (state) => state.authHoldings,
  getEdisResp: (state) => state.edisResp,
  getIsLoading: (state) => state.isLoading,
  getAuthBtnLoading: (state) => state.authBtnLoading,
};

const holdings = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default holdings;
