var data = null;
import router from "../../router";
import store from "../../store";
import { env } from "../../helpers/env";
const chartBus = {
  methods: {
    checkMethod(data) {
      if (router.currentRoute.path != "/chart") {
        router.push({ name: "chart" });
      }
      this.refresh(data);
    },
    async refresh(item) {
      var currentChart = item;

      localStorage.setItem("chartData", JSON.stringify(item));
      //
      let indicators = store.state.chart.chartIndicators;
      var token = currentChart.token;
      var exchange =
        currentChart.exchIndex && currentChart.exchIndex.includes("index")
          ? currentChart.exchIndex
          : currentChart.exchange;
      var symbol =
        currentChart.exchIndex && currentChart.exchIndex.includes("index")
          ? currentChart.symbol
          : currentChart.tradingSymbol;
      store.commit("chart/setCurrentPopoutSymbol", symbol);
      let theme = store.state.selectedTheme;
      data = {
        symbol: symbol,
        interval: "1D",
        token: token,
        exchange: exchange,
        clientId: "GoodWill",
        userId: store.state.login.UCC,
        locale: "en",
        uid: "tradingview_cf3ee",
        chartsStorageUrl: env().CHARTURL,
        chartsStorageVer: "1.1",
        autoSaveDelay: 5,
        debug: false,
        timezone: "Asia/Kolkata",
        loginType: "web",
        showPopout: true,
        isSymbolSearch: false,
        theme: theme,
        session: store.state.login.accessToken,
        preloadProps: {
          interval: "1D",
          indicators: indicators,
        },
      };

      store.commit("chart/setTardeData", data);
      let destination = document.getElementById("chartFrame");
      if (destination && destination.contentWindow && destination.src) {
        let message = {
          id: "changeSymbol",
          message: "?data=" + JSON.stringify(data),
        };

        destination.contentWindow?.postMessage(message, "*");
      } else {
        let tempUrl =
          window.location.protocol == "https:"
            ? env().depolyedUrl
            : env().depolyedUrl;

        store.commit(
          "chart/setChartUrl",
          tempUrl + "?data=" + JSON.stringify(data)
        );
        store.commit("chart/setRefreshArray", destination);
      }
    },
  },
};

export default chartBus;
