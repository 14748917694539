import { commonService } from "../../services/common.service";
import errorHandling from "../../handleError/errHandling";
import router from "@/router/index";
import { loginService } from "@/services/login.services";

const state = {
  userPref: "",
  isTickerTape: "0",
  mwListPosition: "0",
  isStickyOW: "0",
  isTheme: "0",
  sectors: [],
  currentSectorList: [],
  sectorsTabs: [],
  userDetails: "",
  currentSectorObject: "",
  tickerTapeList: [],
  tapLoader: false,
  tickerObjectList: "",
  settingsTab: [
    { name: "Personal Profile", id: 0, active: true },
    { name: "Account Info", id: 1, active: false },
    { name: "Security Settings", id: 2, active: false },
    { name: "Markets", id: 4, active: false },
  ],
  marketwatchType: "My Watchlist",
  securityInfo: "",
  prefLoader: false,
  owType: "0",
  changePassErrorMsg: "",
  pinnedScrips: [],
  sortOrder: 1,
  setPinUpChannel: "",
  sortPosition: "0",
  watchlistView: "0",

  freezeStatus: "",
  enabled: false,
  otpLoader: false,
  freezeLoader: false,
  sentAlready: false,
  freezeReason : "",
  freezeOtp : "",
  isFreezeDialog: false,
};

const actions = {
  async getUserPref({ state, commit, dispatch, rootGetters }, payload) {
    await commonService
      .getUserPref()
      .then(
        (resp) => {
          if (
            (resp.data.status == "Ok" ||
              resp.data.status == "not_ok" ||
              resp.data.status == "Not ok") &&
            (resp.data.message == "Success" ||
              resp.data.message == "Guest User")
          ) {
            commit("setUserPref", resp.data.result);
            if (payload?.from == "login") {
              router.push("/home").catch(() => {});
            }
          }
        },
        (err) => {
          commit("login/setLoader", false, { root: true });
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("login/setLoader", false, { root: true });
      });
  },

  async addUserPref({ commit, rootGetters }, payload) {
    let defaultPref = [];
    if (payload.type == "single") {
      defaultPref.push({
        tag: "SettingsWeb",
        keyVariable: payload.key,
        value: "false",
        userId: rootGetters["login/getUCC"],
      });
    } else {
      defaultPref = [
        {
          tag: "SettingsWeb",
          keyVariable: "WatchListSwitch",
          value: "false",
          userId: rootGetters["login/getUCC"],
        },
        {
          tag: "SettingsWeb",
          keyVariable: "IsTickerTable",
          value: "true",
          userId: rootGetters["login/getUCC"],
        },
        {
          tag: "SettingsWeb",
          keyVariable: "StickyOrderWindow",
          value: "false",
          userId: rootGetters["login/getUCC"],
        },
      ];
    }
    await commonService.addUserPref(defaultPref).then(
      (resp) => {
        if (resp.data.status == "Ok" && resp.data.message == "Success") {
          commit("setUserPref", resp.data.result);
        } else {
          commit("setUserPref", []);
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },

  async updateUserPref({ state, commit, dispatch, rootGetters }, payload) {
    let json = {
      tag: payload.keyVariable,
      value: payload.value,
    };
    await commonService.updateUserPref(json).then(
      (resp) => {
        if (resp.data.status == "Ok" && resp.data.message == "Success") {
          if (resp.data.result) {
            commit("setUserPref", resp.data.result);
          } else {
            dispatch("getUserPref");
          }
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },

  async getProfileData({ state, commit, dispatch, rootGetters }, payload) {
    commit("setPrefLoader", true);
    await commonService
      .getProfileData()
      .then(
        (resp) => {
          if (
            resp.data?.status == "Ok" &&
            resp.data?.message == "Success" &&
            resp.data?.result
          ) {
            commit("setUserDetails", resp.data.result[0]);
          } else {
            commit("setUserDetails", []);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setPrefLoader", false);
      });
  },

  async checkWebsocketStatus(
    { state, commit, dispatch, rootGetters },
    payload
  ) {
    if (
      rootGetters["ws/getConnectionStatus"] &&
      (payload == "sector" ||
        payload == "tickerTape" ||
        payload == "tickerTapeDestory" ||
        payload == "etfs" ||
        payload == "header")
    ) {
      var tempData = {
        data:
          payload == "sector"
            ? state.currentSectorList
            : payload == "tickerTape"
            ? state.tickerTapeList
            : payload == "etfs"
            ? rootGetters["home/getCurrentEtfList"]
            : payload == "header"
            ? state.pinnedScrips
            : [],
        where: payload,
      };
      await dispatch("ws/websocketSubscription", tempData, { root: true });
    }
  },

  async getTickerTapeList({ state, commit, dispatch, rootGetters }, payload) {
    state.tapLoader = true;

    let json = {
      userId: rootGetters["login/getUCC"],
    };
    await commonService
      .getTickerTapeList(json)
      .then(
        (resp) => {
          if (
            resp.data.status == "Ok" &&
            resp.data.message == "Success" &&
            resp.data.result
          ) {
            commit("setTickerTapeList", resp.data.result);
          } else {
            commit("setTickerTapeList", []);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        state.tapLoader = false;
      });
  },

  async subsFloatCards({ state, rootGetters, dispatch }) {
    if (rootGetters["ws/getConnectionStatus"]) {
      var tempData = {
        sub: "NSE|26000#NSE|26009",
        stage: "initial",
      };
      dispatch("ws/floatingCardSubscription", tempData, { root: true });
    }
  },

  async unSubsFloatCards({ state, rootGetters, dispatch }) {
    if (rootGetters["ws/getConnectionStatus"]) {
      var tempData = {
        sub: rootGetters["ws/getFloatCardsSubs"],
        stage: "esc",
      };
      dispatch("ws/floatingCardSubscription", tempData, { root: true });
    }
  },

  async getSecurityInfo({ state, commit, rootGetters, dispatch }, payload) {
    let json = {
      exch: payload.exchange,
      token: payload.token,
    };
    await commonService.getSecurityInfo(json).then(
      (resp) => {
        if (
          resp.data.status == "Ok" &&
          resp.data.message == "Success" &&
          resp.data.result
        ) {
          commit("setSecurityInfo", resp.data.result[0]);
        } else {
          commit("setSecurityInfo", []);
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },

  async changePassword({ state, commit, rootGetters, dispatch }, payload) {
    let json = {
      userId: payload.json.userId,
      oldPwd: payload.json.oldPwd,
      newPwd: payload.json.newPwd,
    };
    await loginService.changePassword(json).then(
      (res) => {
        if (res.data.message && res.status == 200) {
          errorHandling.toaster(
            res.data.message,
            "success",
            "",
            5000,
            "top-right",
            "",
            ""
          );
          if (res.data.message.includes("Your new password will expire")) {
            errorHandling.resetLocal(false);
          }
        } else if (res.data.message) {
          errorHandling.toaster(
            res.data.message,
            "danger",
            "",
            5000,
            "top-right",
            "",
            ""
          );
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },

  async getPinnedScrips({ state, commit, dispatch }) {
    await commonService.getPinnedScrips().then(
      (resp) => {
        if (
          resp.data.status == "Ok" &&
          resp.data.message == "Success" &&
          resp.data?.result?.length != 0
        ) {
          commit("setPinnedScrips", resp.data.result);
        } else {
          commit("setPinnedScrips", []);
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },

  async pinToHeader({ state, commit, dispatch }, payload) {
    commit("setSortOrder", payload?.sortOrder);
    await commonService.pinToHeader(payload).then(
      (resp) => {
        if (resp.data.status == "Ok" && resp.data.message == "Success") {
          dispatch("getPinnedScrips");
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },

  async getFreezeStatus({ state, commit, dispatch, rootGetters }, payload) {
    let json = {
      "userId": rootGetters["login/getUCC"]
  }
    await commonService
      .getFreezeStatus(json)
      .then(
        (resp) => {
          if (
            resp.status == 200 && 
            (resp.data.message.toLowerCase() == "active" || resp.data.message.toLowerCase() == "deactive")
          ) {
            commit("setFreezeStatus", resp.data.message);
            commit("setSentAlready", false);
          }
        },
        (err) => {
          commit("login/setLoader", false, { root: true });
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("login/setLoader", false, { root: true });
      });
  },

  async sendFreezeOtp({ state, commit, dispatch, rootGetters }, payload) {
    await commonService
      .sendFreezeOtp(payload)
      .then(
        (res) => {
          if (res.status == 200 && res.data.status == "Ok") {
            errorHandling.toaster(
              res.data.message,
              "success",
              "",
              3000,
              "top-right",
              " ",
              " "
            );
            commit("setOtpEnabled", true); 
          } else if (res.status == 200 && res.data.status == "Not ok") {
            commit("setSentAlready", false); 
            commit("setOtpEnabled", false);
            errorHandling.toaster(
              res.data.message,
              "danger",
              "",
              3000,
              "top-right",
              " ",
              " "
            );
          } else {
            commit("setOtpEnabled", false);
          }
        },
        (err) => {  
          errorHandling.errLog(err);
          commit("setOtpEnabled", false);
          commit("setFreezeLoader", false);
        }
      )

      .finally(() => {
        commit("setFreezeLoader", false);
      });
  },

  async updateFreezeStatus({ state, commit, dispatch, rootGetters }, payload) {
    commit("setFreezeLoader", true);
    await commonService
      .updateFreezeStatus(payload)
      .then(
        (resp) => {
          if (resp.status == 200 && resp.data.status == "Ok") {
            errorHandling.toaster(
              resp.data.message,
              "success",
              "",
              3000,
              "top-right",
              " ",
              " "
            );
            dispatch("getFreezeStatus");
            commit("setOtpEnabled", false);
          } else if (resp.status == 200 && resp.data.status == "Not ok") {
            errorHandling.toaster(
              resp.data.message,
              "danger",
              "",
              3000,
              "top-right",
              " ",
              " "
            );
          }
        },
        (err) => {
          commit("setFreezeLoader", false);
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setOtpLoader", false);
        commit("setIsFreezeDialog",false)
      });
  },
};

const mutations = {
  resetState(state) {
    state.userPref = "";
    state.isTickerTape = "0";
    state.mwListPosition = "0";
    state.isStickyOW = "0";
    state.isTheme = "0";
    state.sectors = [];
    state.currentSectorList = [];
    state.sectorsTabs = [];
    state.userDetails = "";
    state.currentSectorObject = "";
    state.tickerTapeList = [];
    state.tapLoader = false;
    state.tickerObjectList = "";
    state.settingsTab = [
      { name: "Personal Profile", id: 0, active: true },
      { name: "Account Info", id: 1, active: false },
      { name: "Security Settings", id: 2, active: false },
      { name: "Markets", id: 4, active: false },
    ];
    state.marketwatchType = "My Watchlist";
    state.securityInfo = "";
    state.prefLoader = false;
    state.owType = "0";
    state.changePassErrorMsg = "";
    state.pinnedScrips = [];
    state.sortOrder = 1;
    state.setPinUpChannel = "";
    state.sortPosition = "0";
    state.watchlistView = "0";
  },

  setUserPref(state, payload) {
    state.userPref = payload;
    localStorage.setItem("preference", JSON.stringify(state.userPref));

    state.userPref.forEach((el) => {
      if (el.tag == "tkt") {
        this.commit("pref/setIsTickerTape", el.value);
      }
      if (el.tag == "sow") {
        this.commit("pref/setIsStickyOW", el.value);
      }
      if (el.tag == "mwp") {
        this.commit("pref/setMwListPosition", el.value);
      }
      if (el.tag == "mwo") {
        this.commit("pref/setIsMarketwatch", el.value);
      }
      if (el.tag == "thm") {
        this.commit("pref/setIsTheme", el.value);
      }
      if (el.tag == "owt") {
        this.commit("pref/setOwType", el.value);
      }

      if (el.tag == "dep") {
        this.commit("pref/setWatchlistView", el.value);
      }
      if (el.tag == "mwt") {
        this.commit("pref/setSortPosition", el.value);
      }
    });
  },

  setIsTickerTape(state, payload) {
    state.isTickerTape = payload;
  },

  setIsStickyOW(state, payload) {
    state.isStickyOW = payload;
  },

  setMwListPosition(state, payload) {
    state.mwListPosition = payload;
  },

  setIsTheme(state, payload) {
    state.isTheme = payload;
    if (state.isTheme == "1") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  },

  setOwType(state, payload) {
    state.owType = payload;
  },

  setIsMarketwatch(state, payload) {
    state.marketwatchType =
      payload == "0"
        ? "My Watchlist"
        : payload == "1"
        ? "Predefined Watchlist"
        : payload == "2"
        ? "Option Chain"
        : "My Watchlist";
  },

  setSectors(state, payload) {
    state.sectors = payload;
  },

  setCurrentSectorList(state, payload) {
    state.currentSectorList = payload.data;
    state.currentSectorObject = payload.object;
    payload.where == "sector"
      ? this.dispatch("pref/checkWebsocketStatus", payload.where)
      : "";
  },

  setSectorsTabs(state, payload) {
    let arr = [];
    payload.data.forEach((el, idx) => {
      arr.push({
        name: `${el.sector}`,
        showname: `${el.sector} (${el.scrips?.length})`,
        active: el.sector == payload.sname ? true : false,
        id: idx,
      });
    });
    state.sectorsTabs = arr;
  },

  setUserDetails(state, payload) {
    state.userDetails = payload;
  },

  setTickerTapeList(state, payload) {
    let temp = {};
    for (let item of payload) {
      item.wsToken = `${item.exchange}|${item.token}`;
      let tickers = window.tickers;
      item.lp =
        tickers && tickers[item.wsToken] && tickers[item.wsToken].lp
          ? tickers[item.wsToken].lp
          : item.pdc;
      item.pc =
        tickers &&
        tickers[item.wsToken] &&
        tickers[item.wsToken].pc &&
        tickers[item.wsToken].pc != "inf"
          ? tickers[item.wsToken].pc
          : "0.00";
      temp[item.wsToken] = item;
    }

    state.tickerObjectList = payload.length > 0 ? temp : "";
    state.tickerTapeList = payload;
    this.dispatch("pref/checkWebsocketStatus", "tickerTape");
  },

  setObjectTickerList(state, payload) {
    state.tickerTapeList = payload;
  },

  setSettingsTab(state, payload) {
    let arr = [];
    payload.data.forEach((el, idx) => {
      arr.push({
        name: el.name,
        active: el.name == payload.sname ? true : false,
        id: idx,
      });
    });
    state.settingsTab = arr;
  },

  setSecurityInfo(state, payload) {
    state.securityInfo = payload;
  },

  setPrefLoader(state, payload) {
    state.prefLoader = payload;
  },
  setChangePassErrorMsg(state, payload) {
    state.changePassErrorMsg = payload;
  },

  setPinnedScrips(state, payload) {
    let tickers = window.tickers;
    let subscribe = "";
    payload.forEach((el) => {
      el.tradingSymbol = el?.symbol;
      el.ltp = "0.00";
      el.pc = "0.00";
      el.chg = "0.00";
      el.wsToken = `${el.exchange}|${el.token}`;
      if (tickers) {
        el.ltp = tickers[el?.token]?.lp ? tickers[el?.token]?.lp : el.ltp;
        el.pc = tickers[el?.token]?.pc ? tickers[el?.token]?.pc : el.pc;
      }
      // chart keys
      if(el.segment && el.segment.includes('_idx')){
        el.exchIndex = `${el.exchange}::index`;
      } else {
        el.exchIndex = el.exchange;
      }
      subscribe += `${el.exchange}|${el.token}#`;
    });
    state.pinnedScrips = payload;
    this.commit("pref/setPinUpChannel", subscribe);
    this.dispatch("pref/checkWebsocketStatus", "header");
  },

  setPinUpChannel(state, payload) {
    state.setPinUpChannel = payload;
  },

  setSortOrder(state, payload) {
    state.sortOrder = payload;
  },
  setSortPosition(state, payload) {
    state.sortPosition = payload;
  },
  setWatchlistView(state, payload) {
    state.watchlistView = payload;
  },

  setFreezeStatus(state, payload) {
    state.freezeStatus = payload;
  },
  setSentAlready(state, payload) {
    state.sentAlready = payload;
  },
  setOtpEnabled(state, payload) {
    state.enabled = payload;
  },

  setOtpLoader(state, payload) {
    state.otpLoader = payload;
  },
  setFreezeLoader(state, payload) {
    state.freezeLoader = payload;
  },
  setReason(state, payload) {
    state.freezeReason = payload;
  },
  setFreezeOtp(state, payload) {
    state.freezeOtp = payload;
  },
  setIsFreezeDialog(state, payload) {
    state.isFreezeDialog = payload;
  },
};

const getters = {
  getUserPref: (state) => state.userPref,
  getIsTickerTape: (state) => state.isTickerTape,
  getMwListPosition: (state) => state.mwListPosition,
  getIsStickyOW: (state) => state.isStickyOW,
  // get sectors
  getSectors: (state) => state.sectors,
  getCurrentSectorList: (state) => state.currentSectorList,
  getSectorsTabs: (state) => state.sectorsTabs,
  getUserDetails: (state) => state.userDetails,
  getTickerTapeList: (state) => state.tickerTapeList,
  getSettingsTab: (state) => state.settingsTab,
  getMarketwatchType: (state) => state.marketwatchType,
  getSecurityInfo: (state) => state.securityInfo,
  getIsTheme: (state) => state.isTheme,
  isPrefLoader: (state) => state.prefLoader,
  isOwType: (state) => state.owType,
  changePassErrorMsg: (state) => state.changePassErrorMsg,
  getPinnedScrips: (state) => state.pinnedScrips,
  getSortOrder: (state) => state.sortOrder,
  getPinupChannel: (state) => state.setPinUpChannel,
  getSortPosition: (state) => state.sortPosition,
  getWatchlistView: (state) => state.watchlistView,
};

const pref = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default pref;
