<template>
  <v-dialog
    v-if="!loading"
    v-model="handleInfoDialog"
    width="650px"
    transition="slide-x-reverse-transition"
  >
    <v-card class="pa-5">
      <div class="d-flex align-baseline  ">
        <div :class="getFontSize(16)" class="mx-2">
          {{
            securityInfoData[0]?.tradingSymbol
              ? securityInfoData[0].tradingSymbol
              : "NA"
          }}
        </div>

        <button
        class="pa-0 rounded min-width-40  fsize10"
          depressed
          min-width="36"
          max-width="36"
          height="26"
          :class="[
            securityInfoData[0]?.exchange == 'NSE'
              ? 'buycolor'
              : securityInfoData[0]?.exchange == 'BSE'
              ? 'sellcolor'
              : '',
            getFontSize(10),
          ]"
        >
          {{ securityInfoData[0]?.exchange }}
        </button>
        <!-- TODO: Add lpw tp high price ranger here -->
       
      </div>
      <div class="d-flex">
      <div
                v-if="securityInfoData[0]?.companyName"
                class="d-flex align-center justify-space-between mx-2" 
                :class="getFontSize(14)"
              >
                <!-- <div class="secondaryColor mb-2">Company Name
                </div> -->
                <div id="sart_date_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.companyName }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.isin"
                class="d-flex align-center justify-space-between orderalign"
                :class="getFontSize(14)"
              >
                <div class="secondaryColor mb-2">ISIN  : </div>
                <div id="sart_date_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.isin }}
                </div>
              </div>
              </div>
      <v-divider class="mx-2 mb-2"></v-divider>
      <div class="">
        <div class="py-2">
          <div class="row ma-0">
            <div class="col-6 py-4 px-4">
              <div
                v-if="securityInfoData[0]?.expiry"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Expiry</div>
                <div id="expiry_val" class="primaryColor mb-2">
                  {{
                    securityInfoData[0]?.expiry
                      ? securityInfoData[0].expiry
                      : "NA"
                  }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.lotSize"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"

              >
                <div class="secondaryColor mb-2">Lot Size</div>
                <div id="sart_date_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.lotSize }}
                </div>
              </div>

              <div
                v-if="securityInfoData[0]?.multiplier"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Multiplier</div>
                <div id="min_lot_val" class="primaryColor mb-2">
                  {{ securityInfoData[0].multiplier }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.tickSize"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Tick Size</div>
                <div id="sart_date_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.tickSize }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.lastTradingDate"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Last Trading Date</div>
                <div id="ltd_val" class="primaryColor mb-2">
                  {{
                    securityInfoData[0]?.lastTradingDate
                      ? securityInfoData[0].lastTradingDate
                      : "NA"
                  }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.issueDate"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Issue Date</div>
                <div id="sart_date_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.issueDate }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.listingDate"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Listing Date</div>
                <div id="sart_date_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.listingDate }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.elmMargin"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Elm Margin</div>
                <div id="max_order_size_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.elmMargin }}
                </div>
              </div>
             
            </div>

            <v-divider vertical></v-divider>

            <div class="col-6 py-4 px-4">
              <div
                v-if="securityInfoData[0]?.token"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Token</div>
                <div id="ltt_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.token }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.priceQuoteQty"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Price Quatation</div>
                <div id="price_quatation_val" class="primaryColor mb-2">
                  {{
                    securityInfoData[0]?.priceQuoteQty
                      ? securityInfoData[0].priceQuoteQty
                      : "NA"
                  }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.segment"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Segment</div>
                <div id="max_order_size_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.segment }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.freezeQty"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Freeze Quantity</div>
                <div id="max_order_size_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.freezeQty }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.deliveryMargin"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Delivery Margin</div>
                <div id="max_order_size_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.deliveryMargin }}
                </div>
              </div>
              <div
                v-if="securityInfoData[0]?.varMargin"
                class="d-flex align-center justify-space-between"
                :class="getFontSize(12)"
              >
                <div class="secondaryColor mb-2">Var Margin</div>
                <div id="max_order_size_val" class="primaryColor mb-2">
                  {{ securityInfoData[0]?.varMargin }}
                </div>
              </div>
           
            </div>
            <div class="row pt-4 ma-0 d-flex justify-end">
              <v-btn
                depressed
                outlined
                class="text-capitalize"
                :class="getFontSize(14)"
                width="60px"
                height="32px"
                @click="closeInfoDialog"
                >Close</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import commonFunctions from "@/mixins/commonFunctions";
export default {
  mixins: [commonFunctions],
  data: () => ({
    // infoDialog: true,
  }),
  methods: {
    closeInfoDialog() {
      this.$store.commit("marketWatch/handleInfoDialog", false);
    },
  },
  computed: {
    ...mapState("marketWatch", ["infoDialog", "securityInfoData", "loading"]),
    handleInfoDialog: {
      get() {
        return this.infoDialog;
      },
      set(value) {
        this.$store.commit("marketWatch/handleInfoDialog", value);
      },
    },
  },
};
</script>
