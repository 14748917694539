<template>
  <v-card
    id="watchList"
    tile
    :width="
      !$store.state.isMobileView && !$store.state.isLapView
        ? '375'
        : !$store.state.isMobileView && $store.state.isLapView
        ? '400'
        : '100%'
    "
    class="mkWatch"
    right
    absolute
    @mouseleave="currentIndex = -1"
  >
    <div class="text-capitalize">
      <v-tabs
        grow
        align-tabs="title"
        active-class="active"
        class="border-bottom-mwSearch"
      >
        <v-tab
          v-for="item in mwTabs"
          :key="item"
          :href="`#${item}`"
          @click="currentMwWindow(item)"
          class="text-center"
          :id="`mw_${item}_list_tab`"
          >{{ item }}
        </v-tab>
      </v-tabs>
    </div>
    <div v-click-outside="onClickOutside">
      <div class="d-flex align-center border-bottom-mwSearch">
        <customIcon
          :name="'search'"
          class="mt-1 pl-2"
          :width="'16'"
          :height="'16'"
          :color="$store.state.amogaColor"
        />
        <v-text-field
          height="40"
          :class="getFontSize(13)"
          v-model="search"
          id="search_Inp"
          hide-no-data
          hide-details
          solo
          tile
          flat
          dense
          autocomplete="off"
          maxlength="40"
          open-on-clear
          :placeholder="
            currentMwTab == 'Watchlist'
              ? 'Search eg: Infy, Nifty, Crudeoil etc.,'
              : 'Search for Stock or Company'
          "
          @input="search ? (search = search.toString().toUpperCase()) : ''"
          @keyup.esc="onClickOutside"
          @click="onSearchClick()"
        ></v-text-field>
        <span v-if="search.length > 0" @click="onClickOutside" class="cursor">
          <customIcon
            :name="'close'"
            class="mt-2 pr-2"
            :width="'20'"
            :height="'20'"
            :color="$store.state.amogaColor"
          />
        </span>
        <span
          v-if="!loading"
          class="secondaryColor pr-4"
          :class="getFontSize(14)"
          id="watchList_len"
        >
          {{ watchList.length > 0 && watchData ? watchData?.length : 0 }}/{{
            currentMwTab == "Watchlist" ? 30 : watchData?.length
          }}
        </span>
      </div>

      <div class="height-2">
        <v-progress-linear
          :active="getSearchLoader"
          :indeterminate="getSearchLoader"
          rounded-lg
          height="2"
          color="blue"
        ></v-progress-linear>
      </div>

      <!-- search list dropdown in mkWatch -->
      <!-- Search Component -->
      <search @updatedWatch="changeWatchVal" :watchData="watchData"></search>
    </div>
    <div
      class="scrollContentsMarketWatch"
      v-if="
        watchList.length > 0 &&
        watchData &&
        watchData?.length > 0 &&
        !watchListLoader
      "
      id="curList"
    >
      <!-- <Listing :watchList="watchList" :tab="tab"/> -->
      <v-expansion-panels
        transition="fade-transition"
        :readonly="true"
        v-model="watchData"
        flat
        tile
        accordion
        multiple
      >
        <listing
          :watchData="watchData"
          :tableFontSize="tableFontSize"
          :currentMwTab="currentMwTab"
          @listSheetData="listSheetData"
          @listSheetIndex="listSheetIndex"
          @updateWatchData="changeWatchVal"
        ></listing>
      </v-expansion-panels>
    </div>

    <!-- No data Part -->
    <div
      class="scrollContentsMarketWatch d-flex flex-column align-center justify-center"
      v-if="watchList.length > 0 && !watchData?.length && !watchListLoader"
      id="mw_no_data_div"
    >
      <img
        width="50%"
        src="@/assets/images/no-data-giga.svg"
        id="mw_no_data_img"
        alt="noDataImg"
        class="mb-2"
      />
      NO DATA FOUND
    </div>

    <!-- Footer Tabs -->
    <div class="border-top" id="wli_nav">
      <div class="footerTab border-top-midDark w-100">
        <v-bottom-navigation height="40" absolute> </v-bottom-navigation>
        <div
          :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
          class="v-bottom-navigation justify-start v-item-group v-bottom-navigation--absolute primary--text text--white"
          id="watch_list_nav"
          tile=""
          style="height: 40px; min-width: auto"
        >
          <button
            v-for="(item, index) in currentMwTab == 'Watchlist'
              ? 5
              : predefMenu"
            @click="changeTab(index)"
            :class="{
              'activeMkBtn v-btn--active': activeColorIndex == index,
              'theme--dark': $vuetify.theme.dark,
              'theme--light': !$vuetify.theme.dark,
            }"
            data-v-0d1c536d=""
            type="button"
            class="border-right-dark v-btn v-btn--is-elevated v-btn--has-bg v-size--default"
            :id="`tab_${item}_wl`"
            style="
              height: 40px;
              min-width: 48px;
              border-top: none;
              border-radius: 0%;
              transform: none;
            "
          >
            <span class="v-btn__content"> {{ item }} </span>
          </button>
        </div>

        <v-menu
          transition="scroll-y-reverse-transition"
          left
          top
          :offset-y="true"
          min-width="100"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :ripple="false"
              v-bind="attrs"
              v-on="on"
              tile
              depressed
              active-class="activeMkBtn"
              class="float-right settingsIcon"
              max-width="60"
              min-width="48"
              height="40"
              id="watch_settings_btn"
            >
              <customIcon
                :name="'settings'"
                :width="'18'"
                :height="'18'"
                :color="
                  $vuetify.theme.dark
                    ? '#ffffff'
                    : $store.state.iconBlackColourCode
                "
              />
            </v-btn>
          </template>
          <v-card class="pa-2 d-flex flex-column">
            <span
              class="primaryColor mb-1"
              id="font_resize_label"
              for="resize_toggle_btn"
              >Font Resize</span
            >
            <v-btn-toggle
              color="blue accent-4"
              class="mb-2 font-weight-bold"
              id="resize_toggle_btn"
              denses
            >
              <v-btn
                :class="[
                  tableFontSize == item ? 'v-btn--active v-item--active' : '',
                  getFontSize(12),
                ]"
                v-for="(item, index) in settingsList"
                :key="index"
                :id="`${item}_btn`"
                @click="$store.commit('marketWatch/setTableFontSize', item)"
                >{{ item }}</v-btn
              >
            </v-btn-toggle>
            <span
              class="primaryColor mb-1"
              id="sort_by_label"
              :class="getFontSize(12)"
              >Sort by</span
            >
            <v-btn-toggle
              color="blue accent-4"
              class="mb-2 font-weight-bold"
              id="sort_by_toggle"
              dense
            >
              <v-btn
                :class="getFontSize(12)"
                v-for="(item, index) in filterList"
                :id="`${item}_btn`"
                :key="index"
                @click="sortedArray(item.toLowerCase())"
                >{{ item }}</v-btn
              >
            </v-btn-toggle>
            <span class="primaryColor mb-1" :class="getFontSize(12)"
              >Add Symbol Preference</span
            >
            <v-radio-group
              id="preference_switch"
              v-model="$store.state.preferenceWatch"
              @change="updatePreference($store.state.preferenceWatch)"
              row
            >
              <v-radio label="Top" value="1" id="theme_switch_Top"></v-radio>
              <v-radio
                label="Bottom"
                value="0"
                id="theme_switch_Bottom"
              ></v-radio>
            </v-radio-group>
          </v-card>
        </v-menu>
      </div>
    </div>
    <bottomSheet
      v-if="$store.state.isMobileView"
      :currentData="currentSheetData"
      :currentIndex="currentDepthIndex"
      v-on:from-child="getChildData"
    />
    <infoDialog v-if="!noInfoData" />
  </v-card>
</template>

<script>
import infoDialog from "./info-dialog.vue";
import bottomSheet from "@/components/bottomSheet.vue";
import gtt from "@/mixins/gtt";
import { mapState, mapGetters } from "vuex";
import draggable from "vuedraggable";
import commonFunc from "@/mixins/commonFunctions";
import customIcon from "@/components/customIcon";
import infoDialogComp from "@/views/marketwatch/info-dialog";
import listing from "./listing.vue";
import search from "./search.vue";
import errorHandling from '../../handleError/errHandling.js'
export default {
  name: "watchlist",
  data: () => ({
    activeColorIndex: -1,
    moreIndex: -1,
    alertDetails: {},
    isRotate: false,
    mwTabs: ["Watchlist", "Predefined List"],
    currentMwTab: "Watchlist",
    currentSheetData: [],

    search: "",
    settingsList: ["S", "M", "L", "XL"],
    filterList: ["A-Z", "(%)", "LTP", "Exc"],
    // tableFontSize: "M",
    infoDialog: false,
    currentInfoData: "",
    alertData: "",
    alertPrice: "",
    submitted: false,
    isRotate: false,
    currentDepthIndex: -1,
    timerId: 0,
    tabTimer: 0,
    addScripTimer: 0,
    depthIndex: -1,
    currentIndex: -1,

    watchData: [],
  }),

  methods: {
    updatePreference(val) {
      this.$store.commit("setmwtValue", val);
      this.$store.dispatch("updatePreference", { tag: "mwt", value: val });
    },
    listSheetData(val) {
      this.currentSheetData = val;
    },
    listSheetIndex(val) {
      this.currentDepthIndex = val;
    },
    changeWatchVal(val) {
      if (val.from == "recentList") {
        this.search = val.value;
        this.$store.commit("marketWatch/setShowRecentSearch", false);
      } else {
        this.watchData = val.value;
      }
      this.openPanel(-1, "item")
    },
    getwindowheight(id, where) {
      var offsetHeight =
        this.$store.state.windowHeight -
        document.getElementById(id).getBoundingClientRect()?.top;
      var dropdownHeight = where == "depth" ? 395 : 200;
      offsetHeight < dropdownHeight
        ? (this.isRotate = true)
        : (this.isRotate = false);
    },

    updateWatch() {
      this.watchData = this.watchlistData;
    },

    onClickOutside() {
      if (this.showRecentSearch) {
        this.$store.commit("marketWatch/setShowRecentSearch", false);
      }
      this.search = "";
      this.$store.commit("marketWatch/setSearchList", []);
      clearTimeout(this.timerId);
      clearTimeout(this.addScripTimer);
    },
    async sortedArray(sortType) {
      if (this.watchData) {
        this.activeFilterItem =
          this.activeFilterItem == ""
            ? sortType
            : sortType == this.activeFilterItem
            ? sortType == "a-z"
              ? "z-a"
              : sortType == "(%)"
              ? "despercentage (%)"
              : sortType == "ltp"
              ? "desltp"
              : sortType == "exc"
              ? "desexchange"
              : "a-z"
            : sortType;
        this.activeFilterItem == "a-z"
          ? this.watchData.sort((a, b) =>
              a.formattedInsName > b.formattedInsName
                ? 1
                : b.formattedInsName > a.formattedInsName
                ? -1
                : 0
            )
          : this.activeFilterItem == "(%)"
          ? this.watchData.sort((a, b) =>
              parseFloat(a.pc) > parseFloat(b.pc)
                ? 1
                : parseFloat(b.pc) > parseFloat(a.pc)
                ? -1
                : 0
            )
          : this.activeFilterItem == "ltp"
          ? this.watchData.sort((a, b) =>
              parseFloat(a.ltp) > parseFloat(b.ltp)
                ? 1
                : parseFloat(b.ltp) > parseFloat(a.ltp)
                ? -1
                : 0
            )
          : this.activeFilterItem == "z-a"
          ? this.watchData.sort((a, b) =>
              b.formattedInsName > a.formattedInsName
                ? 1
                : a.formattedInsName > b.formattedInsName
                ? -1
                : 0
            )
          : this.activeFilterItem == "despercentage (%)"
          ? this.watchData.sort((a, b) =>
              parseFloat(b.pc) > parseFloat(a.pc)
                ? 1
                : parseFloat(a.pc) > parseFloat(b.pc)
                ? -1
                : 0
            )
          : this.activeFilterItem == "desltp"
          ? this.watchData.sort((a, b) =>
              parseFloat(b.ltp) > parseFloat(a.ltp)
                ? 1
                : parseFloat(a.ltp) > parseFloat(b.ltp)
                ? -1
                : 0
            )
          : this.activeFilterItem == "desexchange"
          ? this.watchData.sort((a, b) =>
              b.ex > a.exchange ? 1 : a.exchange > b.exchange ? -1 : 0
            )
          : this.watchData.sort((a, b) =>
              a.exchange > b.exchange ? 1 : b.exchange > a.exchange ? -1 : 0
            );
        this.$store.commit("marketWatch/setWatchList", this.watchList);
      }
    },
    async changeTab(value) {
      this.onClickOutside();
      this.$store.commit("marketWatch/setTab", value);
      this.activeColorIndex = value;

      this.currentMwTab == "Watchlist"
        ? localStorage.setItem("watchTab", JSON.stringify(value))
        : localStorage.setItem("preTab", JSON.stringify(value));

      await this.$store.dispatch("marketWatch/changeWatchList").finally(() => {
        this.updateWatch();
      });
      this.$store.commit("marketWatch/setDepthIndex", -1);
    },

    getInfo(currentData) {
      this.$store.dispatch("marketWatch/getSecurityInfo", currentData);
      this.infoDialog = true;
      this.currentInfoData = currentData;
    },

    callalertDialog(val) {
      this.alertPrice = "";
      this.alertData = val;
      this.submitted = false;
      this.$store.commit("marketWatch/setAlertDialog", true);
    },

    async alertSubscribe() {
      this.submitted = true;
      if (this.alertPrice != "" && this.alertPrice != "") {
        await this.$store.dispatch("marketWatch/alertDialog", {
          data: this.alertData,
          alertPrice: this.alertPrice,
        });
      }
    },

    callSearch(value) {
      var temp = {
        value: value.toString().toUpperCase(),
        where: "mwatch",
      };
      clearTimeout(this.timerId);
      this.$store.dispatch("marketWatch/getSearchList", temp);
    },
    async openPanel(index, item, array) {
      //Reset Depth on tab change

      if (this.depthIndex == index) {
        this.depthIndex = this.depthIndex == -1 ? index : -1;
      } else {
        this.depthIndex = index;
      }
      this.$store.commit("marketWatch/setDepthIndex", this.depthIndex);
      if (index != -1) {
        this.$store.dispatch(
          "wsConnection/depthSubscription",
          item.exchange + "|" + item.token
        );
      } else {
        this.$store.dispatch("wsConnection/depthSubscription", "");
      }
    },
    // callOptionChain(item) {
    //   this.$store.dispatch("oc/getUnderlyingExp", {
    //     ins: item.symbol,
    //     exch: item.exchange,
    //   });
    //   let date = new Date(item.expiry);
    //   const options = {
    //     year: "2-digit",
    //     month: "short",
    //     day: "2-digit",
    //     timeZone: "UTC",
    //   };
    //   const dateFormatter = new Intl.DateTimeFormat("en-IN", options);
    //   const formattedDate = dateFormatter.format(date);
    //   let newExp = formattedDate?.toUpperCase()?.toString()?.replace(/-/g, "");

    //   let ins = item.symbol;
    //   let exp = newExp;

    //   if (this.$router.path != "/optionchain") {
    //     this.$store.commit("oc/setInsExp", { ins, exp });
    //     router.push("optionchain");
    //   }
    // },
    getChildData(data) {
      if (data.page == "MkWatch") {
        if (data.action == "buy" || data.action == "sell") {
          this.callOrderWindowByContract(
            data.item,
            data.action,
            false,
            "mkWatch",
            false,
            false,
            false,
            false
          );
        } else if (data.action == "chart") {
          this.callTrandingViewChart(data.item, "mkWatch");
        } else if (data.action == "depth") {
          this.openPanel(data.index, data.item, this.watchData);
        } else if (data.action == "del") {
          this.addorDeleteScrip(data.item, data.action);
        } else if (data.action == "Alert") {
          this.openAlertDialog(data.item);
        } else if (data.action == "oc") {
          this.callOptionChain(data.item);
        } else if (data.action == "info") {
          this.openInfoDialog(data.item);
        } else if (data.action == "gtt") {
          this.openGtt(data.item);
        }
      }
    },
    openGtt(data) {
      window.goodtillWindow = this.listenGttTicks;
      this.callOrderWindowByContract(
        data,
        "buy",
        false,
        "gtt",
        false,
        false,
        false,
        true
      );
      this.$store.commit("gtt/setAction", data.token);
      this.$store.commit("gtt/setGttData", data);
      //this.$store.commit("gtt/setGttWindow", true);
      this.$store.commit("gtt/setGttStatus", true);
    },
    openInfoDialog(currentData) {
      // this.infoData = currentData;
      this.$store.commit("marketWatch/handleInfoDialog", true);
      this.$store.dispatch("marketWatch/getSecurityInfo", currentData);
    },
    async currentMwWindow(item) {
      if (item == "Watchlist") {
        await this.$store.dispatch("marketWatch/setKeys", {
          data: this.iniWatchList,
          where: "initial",
          action: "initial",
        });
      } else {
        await this.$store.dispatch("marketWatch/setKeys", {
          data: this.predefList,
          where: "initial",
          action: "initial",
        });
      }
      this.currentMwTab = item;
      let tab;

      if (item == "Watchlist") {
        tab = JSON.parse(localStorage.getItem("watchTab"));
      } else {
        tab = JSON.parse(localStorage.getItem("preTab"));
      }
      let getTab = tab ? tab : 0;
      this.$store.commit("marketWatch/setTab", getTab);
      this.activeColorIndex = getTab;
      this.changeTab(getTab);
    },
    async addorDeleteScrip(data, type, isSingle, length) {
      this.openPanel(-1, "item");

      let json = {
        userId: this.getUCC,

        scripData: [
          {
            exch: data.exchange,
            token: data.token,
          },
        ],
      };
      if (type == "del") {
        await this.$store.dispatch("marketWatch/deleteSearchList", {
          json,
          data,
        });
      } else if (type == "add" && length < 30) {
        await this.$store
          .dispatch("marketWatch/addSearchList", { json, data })
          .finally(() => {
            this.updateWatch();
          });
      } else if (type == "add" && length >= 30) {
        errorHandling.toaster(
          "",
          "danger",
          "Can't add more than 30 scrips.Use another tab",
          3000,
          "top-right",
          " ",
          " "
        );
      }
      if (isSingle) {
      }
      await this.$store.dispatch("marketWatch/changeWatchList").finally(() => {
        this.updateWatch();
      });
    },
    openAlertDialog(props) {
      this.alertCondition = true;
      this.alertDetails = props;
      this.$store.commit("order/openAlertDialog", {
        openCond: true,
        props: props,
        alertCallSource: "mkWatch",
      });
    },

    onSearchClick() {
      if (this.currentMwTab == "Watchlist" && this.searchList && this.searchList.length == 0) {
        this.$store.commit("marketWatch/setShowRecentSearch", true);
      }
    },
  },
  components: {
    infoDialog,
    bottomSheet,
    draggable,
    customIcon,
    listing,
    search,
    infoDialogComp,
  },
  mixins: [commonFunc, gtt],

  computed: {
    ...mapState("marketWatch", [
      "searchList",
      "securityInfoData",
      "loading",
      "alertLoader",
      "watchList",
      "watchListLoader",
      "noInfoData",
      "predefMenu",
      "predefList",
      "predefData",
      "preTab",
      "watchlistData",
      "iniWatchList",
      "recentSearchData",
      "showRecentSearch",
      "tableFontSize",
    ]),

    ...mapGetters("marketWatch", [
      "getAlertDialog",
      "getMwList",
      "getSearchLoader",
    ]),
    ...mapGetters("login", ["getUCC"]),
    ...mapState(["isMobileView", "isLapView"]),

    alertDialog: {
      get() {
        return this.getAlertDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("marketWatch/setAlertDialog", value);
        }
        return value;
      },
    },
  },

  watch: {
    // search scrips in marketWatch
    search: function (newValue, oldValue) {
      if (this.currentMwTab == "Watchlist") {
        newValue = newValue.trim();
        clearTimeout(this.timerId);
        if (newValue && newValue?.length > 1) {
          this.timerId = setTimeout(() => {
            let isUpdate = false;
            let updateData;
            if (this.recentSearchData.length >= 10) {
              isUpdate = true;
              this.recentSearchData.shift();
              updateData = this.recentSearchData;
            }
            let json = {
              isUpdate: isUpdate,
              data: isUpdate == true ? updateData : newValue,
            };
            this.$store.commit("marketWatch/setRecentSearchData", json);
            this.callSearch(newValue);
          }, 500);
        }
      } else {
        if (newValue) {
          this.watchData = this.watchData.filter((el) =>
            el.formattedInsName.includes(newValue)
          );
        } else {
          if (this.currentMwTab != "Watchlist") {
            this.updateWatch();
          }
        }
      }
    },
  },
  //

  async created() {
    await this.$store.dispatch("marketWatch/getwatchList");

    var watchTab = JSON.parse(localStorage.getItem("watchTab"));
    var predefTab = JSON.parse(localStorage.getItem("preTab"));
    var tab;
    var prewatchTab;
    watchTab ? (tab = watchTab) : (tab = 0);
    predefTab ? (prewatchTab = predefTab) : (prewatchTab = 0);
    this.currentMwTab == "Watchlist"
      ? await this.changeTab(tab)
      : this.currentMwTab == "Predefined List"
      ? await this.changeTab(prewatchTab)
      : "";
  },
  destroyed() {
    delete window.watch;
    delete window.addwatch;
  },
};
</script>

<style scoped>
.mkWatch {
  position: fixed;
  right: 0;
  top: 56px;
  height: calc(100vh - 56px);
  resize: horizontal;
  border-left: 1px solid#a2a2a2 !important;
}

.scrollContentsMarketWatch {
  height: calc(100vh - 186px);
  overflow-y: auto;
}

.activeMkBtn {
  background-color: #dee9fe !important;
  color: #0028ff !important;
  font-weight: bold !important;
}

.settingsBtnGroup {
  color: #170055 !important;
  background-color: #dfd3ff !important;
}

.theme--dark .activeMkBtn {
  background-color: #213b52 !important;
  color: #2992ec !important;
}

::v-deep .v-input__slot {
  box-shadow: none !important;
}

input[type="text"] {
  text-indent: 20px !important;
}

.searchlist {
  list-style: none !important;
}

.searchContent {
  z-index: 2;
  max-height: calc(100vh - 145px) !important;
  overflow-y: auto;
  position: absolute;
  width: 100%;
}

::v-deep .v-expansion-panel-header {
  display: block !important;
  padding: 8px 16px !important;
  transition: none !important;
  line-height: unset !important;
}

::v-deep .v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}

::v-deep .v-expansion-panel {
  transition: none !important;
  border-bottom: 1px solid #e9e9e9 !important;
}

.theme--dark .v-expansion-panel {
  border-bottom: 1px solid #393939 !important;
}

.theme--dark .searchContent ul {
  background-color: #1e1e1e;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

::v-deep .v-expansion-panel::before {
  box-shadow: none !important;
}

/* ::v-deep .v-icon {
  font-size: 18px !important;
} */
::v-deep .v-item-group.v-bottom-navigation {
  width: 87% !important;
  box-shadow: none !important;
}

/* tabs overwrite */
.mkWatch .theme--light.v-tabs .v-tab--active {
  opacity: 0.12 !important;
}

.footerTab {
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.settingsIcon {
  width: calc(100% - 50px);
}

.hoverImg {
  max-width: 60%;
}

.theme--dark .stsImglight {
  display: none;
}

.theme--light .stsImgdark {
  display: none;
}

.mkWatch .theme--light.v-tabs .v-tab--active {
  opacity: 1 !important;
}
.v-tab {
  opacity: 1 !important;
  letter-spacing: 0 !important;
}
.height-2 {
  height: 2px !important;
}

.v-expansion-panel-header:before {
  background-color: white !important;
}
.rowMk {
  display: flex !important;
  flex-wrap: wrap !important;
  flex: 1 1 auto !important;
}
.justify-between {
  justify-content: space-between;
}
.v-slide-group__content {
  flex: auto !important;
  justify-content: space-between !important;
}
.neverHidden {
  display: none !important;
}
</style>
